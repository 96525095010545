import { useCurrentUser } from '../auth/auth'
import { useMemo } from 'react'

const useUserCurrency = () => {
    const { user } = useCurrentUser()
    return useMemo<'CZK' | 'EUR'>(() => {
        return user?.language === 'CS' ? 'CZK' : 'EUR'
    }, [user?.specialType])
}

export default useUserCurrency
